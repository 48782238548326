import React from 'react'
//import './App.css'
import Form1 from './component/Form1'
import CgaForm from './component/CgaForm'
import CgaForm2 from './component/CgaForm2'

function App() {
  return (
    <div>
      {/*<Form1 />*/}
      {/*<CgaForm />*/}
      <CgaForm2 />
    </div>
  )
}

export default App
