import React from 'react'
import { Col, Container, Row, Table, Form } from 'react-bootstrap'
import data from './Data'

const RLC = () => {
  console.log(data)
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4>
              Required Level of Competency (RLC) - The level of competency that
              the jobholders must achieve/have{' '}
            </h4>
            <h4>
              Current Level of Competency (CLC) - The level of competency that
              the current jobholder has actually achieved/had
            </h4>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover variant="dark">
              <thead>
                <tr>
                  <th rowSpan={3}>No</th>
                  <th colSpan={2}>COMPETENCY LISTING</th>
                  <th colSpan={5}>LEVEL OF IMPORTANCE</th>
                  <th colSpan={5}>CURRENT LEVEL OF COMPETENCY</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td>COMPETENCY</td>
                  <td>INDICATOR</td>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                  <td>5</td>
                  <td>1</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                  <td>5</td>
                </tr>
                <tr>
                  <td>A</td>
                  <td>Leadership Competencies </td>
                  <td colSpan={11}>
                    Applies leadership competencies to successfully execute
                    strategy
                  </td>
                </tr>
                {data.map((val, key) => {
                  return (
                    <>
                      <tr>
                        <td rowSpan={val.indicator4 ? 4 : 3}>{val.number}</td>
                        <td rowSpan={val.indicator4 ? 4 : 3}>
                          {val.competency}
                        </td>
                        <td>{val.indicator1}</td>
                        <td>
                          <Form.Check
                            inline
                            name={'group' + key + 1}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 1}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 1}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 1}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 1}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 2}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 2}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 2}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 2}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 2}
                            type="radio"
                          ></Form.Check>
                        </td>
                      </tr>
                      <tr>
                        <td>{val.indicator2}</td>
                        <td>
                          <Form.Check
                            inline
                            name={'group' + key + 3}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 3}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 3}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 3}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 3}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 4}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 4}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 4}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 4}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 4}
                            type="radio"
                          ></Form.Check>
                        </td>
                      </tr>
                      <tr>
                        <td>{val.indicator3}</td>
                        <td>
                          <Form.Check
                            inline
                            name={'group' + key + 5}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 5}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 5}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 5}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 5}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 6}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 6}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 6}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 6}
                            type="radio"
                          ></Form.Check>
                        </td>
                        <td>
                          {' '}
                          <Form.Check
                            inline
                            name={'group' + key + 6}
                            type="radio"
                          ></Form.Check>
                        </td>
                      </tr>
                      {val.indicator4 ? (
                        <tr>
                          <td>{val.indicator4}</td>
                          <td>
                            <Form.Check
                              inline
                              name={'group' + key + 7}
                              type="radio"
                            ></Form.Check>
                          </td>
                          <td>
                            {' '}
                            <Form.Check
                              inline
                              name={'group' + key + 7}
                              type="radio"
                            ></Form.Check>
                          </td>
                          <td>
                            {' '}
                            <Form.Check
                              inline
                              name={'group' + key + 7}
                              type="radio"
                            ></Form.Check>
                          </td>
                          <td>
                            {' '}
                            <Form.Check
                              inline
                              name={'group' + key + 7}
                              type="radio"
                            ></Form.Check>
                          </td>
                          <td>
                            {' '}
                            <Form.Check
                              inline
                              name={'group' + key + 7}
                              type="radio"
                            ></Form.Check>
                          </td>
                          <td>
                            {' '}
                            <Form.Check
                              inline
                              name={'group' + key + 8}
                              type="radio"
                            ></Form.Check>
                          </td>
                          <td>
                            {' '}
                            <Form.Check
                              inline
                              name={'group' + key + 8}
                              type="radio"
                            ></Form.Check>
                          </td>
                          <td>
                            {' '}
                            <Form.Check
                              inline
                              name={'group' + key + 8}
                              type="radio"
                            ></Form.Check>
                          </td>
                          <td>
                            {' '}
                            <Form.Check
                              inline
                              name={'group' + key + 8}
                              type="radio"
                            ></Form.Check>
                          </td>
                          <td>
                            {' '}
                            <Form.Check
                              inline
                              name={'group' + key + 8}
                              type="radio"
                            ></Form.Check>
                          </td>
                        </tr>
                      ) : (
                        ''
                      )}
                    </>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default RLC
