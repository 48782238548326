import React, { useState, useEffect } from 'react'
import axios from 'axios'
import {
  Alert,
  Card,
  Table,
  InputGroup,
  Form,
  Row,
  Col,
  Button,
} from 'react-bootstrap'
import packageJson from '../../package.json'
const { config } = packageJson

const CgaForm2 = () => {
  const [comp, setcomp] = useState([])
  const [indi, setindi] = useState([])
  const [name, setname] = useState('')
  const [design, setdesign] = useState('')
  const [depart, setdepart] = useState('')
  const [ywe, setywe] = useState('')
  const [tywe, setTywe] = useState('')
  const [hq, sethq] = useState('')
  const [answer, setanswer] = useState([])

  const handleFormSubmit = async () => {
    try {
      const response = await axios.post(
        `${config.REACT_APP_API_ENDPOINT}/userandanswer`,
        {
          name,
          design,
          depart,
          ywe,
          tywe,
          hq,
          answer: answer,
        },
      )

      if (response.status === 200) {
        alert('Data inserted successfully!')
        // Handle success (show success message, update UI, etc.)
      } else {
        console.error('Failed to insert data')
        // Handle error (show error message, etc.)
      }
    } catch (error) {
      console.error('Error inserting data:', error)
      // Handle error (show error message, etc.)
    }
  }

  const handleSurveyOnChange = async (
    index,
    compid,
    indiid,
    locscore,
    loiscore,
    sec,
  ) => {
    const compvar = 'comp_id'
    const indivar = 'indi_id'
    const locvar = 'loc_score'
    const loivar = 'loi_score'

    const newArray = [...answer]
    newArray[index] = { ...newArray[index], [compvar]: compid }
    newArray[index] = { ...newArray[index], [indivar]: indiid }
    sec === 1
      ? (newArray[index] = { ...newArray[index], [loivar]: loiscore })
      : (newArray[index] = { ...newArray[index], [locvar]: locscore })

    setanswer(newArray)
  }

  useEffect(() => {
    const fetchAllComp = async () => {
      try {
        await axios
          .get(`${config.REACT_APP_API_ENDPOINT}/comps`)
          .then((response) => {
            if (response) {
              setcomp(response.data)
            }
          })
      } catch (err) {
        console.log(err)
      }
    }
    const fetchAllIndi = async () => {
      try {
        await axios
          .get(`${config.REACT_APP_API_ENDPOINT}/indi`)
          .then((response) => {
            if (response) {
              setindi(response.data)
            }
          })
      } catch (err) {
        console.log(err)
      }
    }
    fetchAllComp()
    fetchAllIndi()
    console.log(answer)
  }, [answer])
  return (
    <>
      <Card className="m-4">
        <Card.Header>
          <center>
            <b>
              SUCCESSOR COMPETENCY ENHANCEMENT AND DEVELOPMENT (SUCCEED)
              PROGRAMME HARWOOD TIMBER SDN BHD
            </b>
          </center>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleFormSubmit}>
            <Row>
              <Col lg={4}>
                <Alert variant="info">
                  <strong>General Instruction:</strong> Please evaluate whether
                  the following competencies are important/relevant to manager
                  and determine the level of competencies that are required for
                  manager, and what is your current level of competency by using
                  the following scale: <br />
                  <br />
                  <Card bg="info" text="light" className="mb-2">
                    <Card.Header>
                      <center>Level of Important (LOI) Scale</center>
                    </Card.Header>
                    <Table>
                      <thead>
                        <tr>
                          <th>Level</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>
                            This competency is not relevant or important to the
                            job position
                          </td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            This competency is quite important/relevant to the
                            job position
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            This competency is important/relevant to the job,
                            but not critical
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            This competency is very important/relevant and
                            critical to the job position
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            This competency is compulsory to the job position
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                  <Card bg="info" text="light" className="mb-2">
                    <Card.Header>
                      <center>Level of Competency (LOC) Scale</center>
                    </Card.Header>
                    <Table>
                      <thead>
                        <tr>
                          <th>Level</th>
                          <th>Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>0</td>
                          <td>
                            Don’t have any knowledge related to the competency
                          </td>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Able to state/aware of the competency</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>
                            Able to explain the meaning and how to apply/use the
                            competency in the job
                          </td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>
                            Able to actually apply, practice, perform or use the
                            competency effectively on the job
                          </td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>
                            Able to coach, teach or evaluate others on how to
                            apply or perform the competency
                          </td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>
                            Able to create, develop, integrate, re-engineer,
                            formulate new models, process, ideas
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card>
                </Alert>
              </Col>
              <Col lg={8}>
                <Card bg="light" text="info">
                  <Card.Header>Evaluation</Card.Header>
                  <Card.Body>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        Name
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => setname(e.target.value)}
                        value={name}
                      />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        Designation
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => setdesign(e.target.value)}
                        value={design}
                      />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        Department
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => setdepart(e.target.value)}
                        value={depart}
                      />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        Years of Working Experience
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => setywe(e.target.value)}
                        value={ywe}
                      />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        Total Years of Working Experience
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => setTywe(e.target.value)}
                        value={tywe}
                      />
                    </InputGroup>
                    <InputGroup size="sm" className="mb-3">
                      <InputGroup.Text id="inputGroup-sizing-sm">
                        Highest Qualification
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => sethq(e.target.value)}
                        value={hq}
                      />
                    </InputGroup>
                  </Card.Body>
                  <Table responsive bordered>
                    <thead>
                      <tr>
                        <th rowSpan={3}>No</th>
                        <th colSpan={2}>
                          <center>COMPETENCY LISTING</center>
                        </th>
                        <th colSpan={5}>
                          <center>LEVEL OF IMPORTANCE</center>
                        </th>
                        <th colSpan={5}>
                          <center>CURRENT LEVEL OF COMPETENCY</center>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td>
                          <center>COMPETENCY</center>
                        </td>
                        <td>
                          <center>INDICATOR</center>
                        </td>
                        <td>
                          <center>1</center>
                        </td>
                        <td>
                          <center>2</center>
                        </td>
                        <td>
                          <center>3</center>
                        </td>
                        <td>
                          <center>4</center>
                        </td>
                        <td>
                          <center>5</center>
                        </td>
                        <td>
                          <center>1</center>
                        </td>
                        <td>
                          <center>2</center>
                        </td>
                        <td>
                          <center>3</center>
                        </td>
                        <td>
                          <center>4</center>
                        </td>
                        <td>
                          <center>5</center>
                        </td>
                      </tr>
                      <tr>
                        <td>A</td>
                        <td>Leadership Competencies </td>
                        <td colSpan={11}>
                          <center>
                            Applies leadership competencies to successfully
                            execute strategy
                          </center>
                        </td>
                      </tr>
                      {comp.map((val, key) => {
                        const indicatorsForCurrentCompetency = indi.filter(
                          (flt) => flt.competency_id === val.competency_id,
                        )

                        return (
                          <React.Fragment key={key}>
                            <tr>
                              <td
                                rowSpan={indicatorsForCurrentCompetency.length}
                              >
                                {key + 1}
                              </td>
                              <td
                                rowSpan={indicatorsForCurrentCompetency.length}
                              >
                                {val.competency_name}
                              </td>
                              {indicatorsForCurrentCompetency
                                .slice(0, 1)
                                .map((val2, key2) => (
                                  <React.Fragment key={key2}>
                                    <td>{val2.indicator_desc}</td>
                                    <td>
                                      <Form.Check
                                        inline
                                        name={'group1' + val2.indicator_id}
                                        type="radio"
                                        value={1}
                                        onChange={(e) =>
                                          handleSurveyOnChange(
                                            val2.indicator_id - 1,
                                            val.competency_id,
                                            val2.indicator_id,
                                            e.target.value,
                                            e.target.value,
                                            1,
                                          )
                                        }
                                        required
                                      ></Form.Check>
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        name={'group1' + val2.indicator_id}
                                        type="radio"
                                        value={2}
                                        onChange={(e) =>
                                          handleSurveyOnChange(
                                            val2.indicator_id - 1,
                                            val.competency_id,
                                            val2.indicator_id,
                                            e.target.value,
                                            e.target.value,
                                            1,
                                          )
                                        }
                                        required
                                      ></Form.Check>
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        name={'group1' + val2.indicator_id}
                                        type="radio"
                                        value={3}
                                        onChange={(e) =>
                                          handleSurveyOnChange(
                                            val2.indicator_id - 1,
                                            val.competency_id,
                                            val2.indicator_id,
                                            e.target.value,
                                            e.target.value,
                                            1,
                                          )
                                        }
                                        required
                                      ></Form.Check>
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        name={'group1' + val2.indicator_id}
                                        type="radio"
                                        value={4}
                                        onChange={(e) =>
                                          handleSurveyOnChange(
                                            val2.indicator_id - 1,
                                            val.competency_id,
                                            val2.indicator_id,
                                            e.target.value,
                                            e.target.value,
                                            1,
                                          )
                                        }
                                        required
                                      ></Form.Check>
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        name={'group1' + val2.indicator_id}
                                        type="radio"
                                        value={5}
                                        onChange={(e) =>
                                          handleSurveyOnChange(
                                            val2.indicator_id - 1,
                                            val.competency_id,
                                            val2.indicator_id,
                                            e.target.value,
                                            e.target.value,
                                            1,
                                          )
                                        }
                                        required
                                      ></Form.Check>
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        name={'group2' + val2.indicator_id}
                                        type="radio"
                                        value={1}
                                        onChange={(e) =>
                                          handleSurveyOnChange(
                                            val2.indicator_id - 1,
                                            val.competency_id,
                                            val2.indicator_id,
                                            e.target.value,
                                            e.target.value,
                                            2,
                                          )
                                        }
                                        required
                                      ></Form.Check>
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        name={'group2' + val2.indicator_id}
                                        type="radio"
                                        value={2}
                                        onChange={(e) =>
                                          handleSurveyOnChange(
                                            val2.indicator_id - 1,
                                            val.competency_id,
                                            val2.indicator_id,
                                            e.target.value,
                                            e.target.value,
                                            2,
                                          )
                                        }
                                        required
                                      ></Form.Check>
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        name={'group2' + val2.indicator_id}
                                        type="radio"
                                        value={3}
                                        onChange={(e) =>
                                          handleSurveyOnChange(
                                            val2.indicator_id - 1,
                                            val.competency_id,
                                            val2.indicator_id,
                                            e.target.value,
                                            e.target.value,
                                            2,
                                          )
                                        }
                                        required
                                      ></Form.Check>
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        name={'group2' + val2.indicator_id}
                                        type="radio"
                                        value={4}
                                        onChange={(e) =>
                                          handleSurveyOnChange(
                                            val2.indicator_id - 1,
                                            val.competency_id,
                                            val2.indicator_id,
                                            e.target.value,
                                            e.target.value,
                                            2,
                                          )
                                        }
                                        required
                                      ></Form.Check>
                                    </td>
                                    <td>
                                      <Form.Check
                                        inline
                                        name={'group2' + val2.indicator_id}
                                        type="radio"
                                        value={5}
                                        onChange={(e) =>
                                          handleSurveyOnChange(
                                            val2.indicator_id - 1,
                                            val.competency_id,
                                            val2.indicator_id,
                                            e.target.value,
                                            e.target.value,
                                            2,
                                          )
                                        }
                                        required
                                      ></Form.Check>
                                    </td>
                                    {/* ... Other radio buttons for the first indicator */}
                                  </React.Fragment>
                                ))}
                            </tr>
                            {indicatorsForCurrentCompetency
                              .slice(1)
                              .map((val2, key2) => (
                                <tr key={key2}>
                                  <td>{val2.indicator_desc}</td>
                                  <td>
                                    <Form.Check
                                      inline
                                      name={'group1' + val2.indicator_id}
                                      type="radio"
                                      value={1}
                                      onChange={(e) =>
                                        handleSurveyOnChange(
                                          val2.indicator_id - 1,
                                          val.competency_id,
                                          val2.indicator_id,
                                          e.target.value,
                                          e.target.value,
                                          1,
                                        )
                                      }
                                      required
                                    ></Form.Check>
                                  </td>
                                  <td>
                                    <Form.Check
                                      inline
                                      name={'group1' + val2.indicator_id}
                                      type="radio"
                                      value={2}
                                      onChange={(e) =>
                                        handleSurveyOnChange(
                                          val2.indicator_id - 1,
                                          val.competency_id,
                                          val2.indicator_id,
                                          e.target.value,
                                          e.target.value,
                                          1,
                                        )
                                      }
                                      required
                                    ></Form.Check>
                                  </td>
                                  <td>
                                    <Form.Check
                                      inline
                                      name={'group1' + val2.indicator_id}
                                      type="radio"
                                      value={3}
                                      onChange={(e) =>
                                        handleSurveyOnChange(
                                          val2.indicator_id - 1,
                                          val.competency_id,
                                          val2.indicator_id,
                                          e.target.value,
                                          e.target.value,
                                          1,
                                        )
                                      }
                                      required
                                    ></Form.Check>
                                  </td>
                                  <td>
                                    <Form.Check
                                      inline
                                      name={'group1' + val2.indicator_id}
                                      type="radio"
                                      value={4}
                                      onChange={(e) =>
                                        handleSurveyOnChange(
                                          val2.indicator_id - 1,
                                          val.competency_id,
                                          val2.indicator_id,
                                          e.target.value,
                                          e.target.value,
                                          1,
                                        )
                                      }
                                      required
                                    ></Form.Check>
                                  </td>
                                  <td>
                                    <Form.Check
                                      inline
                                      name={'group1' + val2.indicator_id}
                                      type="radio"
                                      value={5}
                                      onChange={(e) =>
                                        handleSurveyOnChange(
                                          val2.indicator_id - 1,
                                          val.competency_id,
                                          val2.indicator_id,
                                          e.target.value,
                                          e.target.value,
                                          1,
                                        )
                                      }
                                      required
                                    ></Form.Check>
                                  </td>
                                  <td>
                                    <Form.Check
                                      inline
                                      name={'group2' + val2.indicator_id}
                                      type="radio"
                                      value={1}
                                      onChange={(e) =>
                                        handleSurveyOnChange(
                                          val2.indicator_id - 1,
                                          val.competency_id,
                                          val2.indicator_id,
                                          e.target.value,
                                          e.target.value,
                                          2,
                                        )
                                      }
                                      required
                                    ></Form.Check>
                                  </td>
                                  <td>
                                    <Form.Check
                                      inline
                                      name={'group2' + val2.indicator_id}
                                      type="radio"
                                      value={2}
                                      onChange={(e) =>
                                        handleSurveyOnChange(
                                          val2.indicator_id - 1,
                                          val.competency_id,
                                          val2.indicator_id,
                                          e.target.value,
                                          e.target.value,
                                          2,
                                        )
                                      }
                                      required
                                    ></Form.Check>
                                  </td>
                                  <td>
                                    <Form.Check
                                      inline
                                      name={'group2' + val2.indicator_id}
                                      type="radio"
                                      value={3}
                                      onChange={(e) =>
                                        handleSurveyOnChange(
                                          val2.indicator_id - 1,
                                          val.competency_id,
                                          val2.indicator_id,
                                          e.target.value,
                                          e.target.value,
                                          2,
                                        )
                                      }
                                      required
                                    ></Form.Check>
                                  </td>
                                  <td>
                                    <Form.Check
                                      inline
                                      name={'group2' + val2.indicator_id}
                                      type="radio"
                                      value={4}
                                      onChange={(e) =>
                                        handleSurveyOnChange(
                                          val2.indicator_id - 1,
                                          val.competency_id,
                                          val2.indicator_id,
                                          e.target.value,
                                          e.target.value,
                                          2,
                                        )
                                      }
                                      required
                                    ></Form.Check>
                                  </td>
                                  <td>
                                    <Form.Check
                                      inline
                                      name={'group2' + val2.indicator_id}
                                      type="radio"
                                      value={5}
                                      onChange={(e) =>
                                        handleSurveyOnChange(
                                          val2.indicator_id - 1,
                                          val.competency_id,
                                          val2.indicator_id,
                                          e.target.value,
                                          e.target.value,
                                          2,
                                        )
                                      }
                                      required
                                    ></Form.Check>
                                  </td>
                                  {/* ... Other radio buttons for the subsequent indicators */}
                                </tr>
                              ))}
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                  </Table>
                  <Card.Footer>
                    <center>
                      <Button type="submit">Submit</Button>
                    </center>
                  </Card.Footer>
                </Card>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </>
  )
}

export default CgaForm2
